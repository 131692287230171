import { graphql } from "gatsby";
import React from "react";
import { OwnBrandHeroContent } from "./OwnBrandHeroContent";
import { ClassicSyrupsHeroImage as OwnBrandHeroImage } from "../ClassicSyrupsHero/ClassicSyrupsHeroImage";
import "./styles.css";

const OwnBrandHero = ({ background, alt, subtitle, title, definition, advantages }) => {
  return (
    <section className="OwnBrandHero">
      <div className="Content">
        <OwnBrandHeroContent subtitle={subtitle} title={title} definition={definition} advantages={advantages} />
      </div>

      <OwnBrandHeroImage background={background} alt={alt} />
    </section>
  );
};

OwnBrandHero.getLivePreviewData = ({ data, getAsset }) => ({
  ownBrandHero: {
    ...data.ownBrandHero,
    background: getAsset(data.ownBrandHero.background).url,
  },
});

const ownBrandHeroQuery = graphql`
  fragment OwnBrandHeroFragment on MarkdownRemarkFrontmatter {
    ownBrandHero {
      background {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      alt
      subtitle
      title
      definition
      advantages
    }
  }
`;

export { OwnBrandHero, ownBrandHeroQuery };
