import { graphql } from "gatsby";
import React from "react";
import { Image } from "../Image";
import "./styles.css";

const OwnBrandTrademarks = ({ subtitle, title, trademarks }) => {
  return (
    <div className="OwnBrandTrademarks__background">
      <div className="[ OwnBrandTrademarks__container ] [ Content ]">
        <div className="[ OwnBrandTrademarks__subTitle ] [ Font_smallHeaderSerif ]">{subtitle}</div>
        <div className="[ OwnBrandTrademarks__title ] [ Font_mediumHeaderSerif sm:Font_headerSerif ]">{title}</div>
        <ul className="OwnBrandTrademarks__list">
          {trademarks.map(trademark => (
            <div className="OwnBrandTrademarks__card">
              <li className="Font_smallHeader" key={trademark.title}>
                <Image image={trademark.background} alt={trademark.alt} />
                <div className="OwnBrandTrademarks__cardTitle">{trademark.title}</div>
              </li>
            </div>
          ))}
        </ul>
      </div>
    </div>
  );
};

OwnBrandTrademarks.getLivePreviewData = ({ data, getAsset }) => ({
  ownBrandTrademarks: {
    ...data.ownBrandTrademarks,
    trademarks: {
      ...data.ownBrandTrademarks.trademarks,
      background: getAsset(data.ownBrandTrademarks.trademarks.background).url,
    },
  },
});

const ownBrandTrademarksQuery = graphql`
  fragment OwnBrandTrademarksFragment on MarkdownRemarkFrontmatter {
    ownBrandTrademarks {
      subtitle
      title
      trademarks {
        background {
          childImageSharp {
            fluid(maxWidth: 1088, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        alt
        title
      }
    }
  }
`;

export { OwnBrandTrademarks, ownBrandTrademarksQuery };
