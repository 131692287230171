import React from "react";
import { HexagonYellow } from "../../ContactUs/ContactUsContainer/icons/HexagonYellow";

import "./styles.css";

const OwnBrandHeroContent = ({ subtitle, title, definition, advantages }) => {
  return (
    <div className="OwnBrandHeroContent">
      <div className="[ OwnBrandHeroContent__subtitle ] [ Font_smallHeaderCaps sm:Font_mediumTextCaps Color_lightGrey ]">
        {subtitle}
      </div>
      <h1 className="[ OwnBrandHeroContent__title ] [ Font_bigHeaderSerif Color_black ]">{title}</h1>
      <div className="OwnBrandHeroContent__featuresContainer">
        <HexagonYellow className="OwnBrandHeroContent__hexagon" />
        <h2 className="[ OwnBrandHeroContent__featuresTitle ] [ Font_smallHeader ]">{definition}</h2>
      </div>

      <ul className="[ OwnBrandHeroContent__featuresList ]  [ Font_regularText ]">
        {advantages.map(advantage => (
          <li key={advantage} className="OwnBrandHeroContent__featuresListItem">
            <span>&#8226;</span>
            <span>{advantage}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export { OwnBrandHeroContent };
