import React from "react";
import Helmet from "react-helmet";
import { Layout } from "../components/Layout";
import { OwnBrandHero } from "../components/OwnBrandHero";
import { OwnBrandOpportunities } from "../components/OwnBrandOpportunities";
import { OwnBrandTrademarks } from "../components/OwnBrandTrademarks";

const OwnBrandPageTemplate = ({ layout, ...props }) => {
  return (
    <>
      <Helmet>
        <title>{props.seo.title}</title>
        <meta name="description" content={props.seo.description} />
        <meta name="keywords" content={props.seo.keywords} />
      </Helmet>

      <OwnBrandHero {...props.ownBrandHero} />
      <OwnBrandTrademarks {...props.ownBrandTrademarks} />
      <OwnBrandOpportunities formBody={layout.formBody} {...props.ownBrandOpportunities} />
    </>
  );
};

OwnBrandPageTemplate.getLivePreviewData = props => ({
  ...Layout.getLivePreviewData(props),
  ...OwnBrandHero.getLivePreviewData(props),
  ...OwnBrandTrademarks.getLivePreviewData(props),
  ...OwnBrandOpportunities.getLivePreviewData(props),
});

const OwnBrandPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout {...data.layout.frontmatter}>
      <OwnBrandPageTemplate layout={data.layout.frontmatter} {...frontmatter} />
    </Layout>
  );
};

const pageQuery = graphql`
  query OwnBrandPageTemplate {
    layout: markdownRemark(frontmatter: { meta: { eq: "layout" } }) {
      frontmatter {
        ...LayoutFragment
        formBody {
          policy
          buttonLabel
          successMessage
          successButtonLabel
          errorMessage
          errorButtonLabel
        }
        partners {
          childImageSharp {
            fluid(maxWidth: 192) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    }

    markdownRemark(frontmatter: { templateKey: { eq: "own-brand" } }) {
      frontmatter {
        seo {
          title
          description
          keywords
        }

        ...OwnBrandHeroFragment
        ...OwnBrandTrademarksFragment
        ...OwnBrandOpportunitiesFragment
      }
    }
  }
`;

export default OwnBrandPage;

export { pageQuery, OwnBrandPageTemplate };
