import { graphql } from "gatsby";
import React from "react";
import { ContactUs } from "../ContactUs";
import "./styles.css";

const OwnBrandOpportunities = ({ title, products, formHeader, formBody }) => {
  return (
    <ContactUs
      className="OwnBrandOpportunities"
      slogan={title}
      stages={products}
      formHeader={formHeader}
      formBody={formBody}
      sloganIconColor="violet"
    />
  );
};

OwnBrandOpportunities.getLivePreviewData = ({ data, getAsset }) => ({
  ownBrandOpportunities: {
    ...data.ownBrandOpportunities,
  },
});

const ownBrandOpportunitiesQuery = graphql`
  fragment OwnBrandOpportunitiesFragment on MarkdownRemarkFrontmatter {
    ownBrandOpportunities {
      title
      products
      formHeader {
        title
        text
      }
    }
  }
`;

export { OwnBrandOpportunities, ownBrandOpportunitiesQuery };
